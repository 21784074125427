import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'

export const ProductSuggestContainer = styled.div`
  margin-bottom: 5em;

  @media (max-width: ${breakpoints.s}px) {
    margin: auto;
    width: 100%;
  }
`

export const ProductSuggestTitle = styled.h2`
  text-align: center;
  font-size: 3em;
  font-weight: 400;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.7em;
  }
`

export const ProductSuggestWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`
