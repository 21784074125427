import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { breakpoints } from '../../utils/styles'
import { Link } from 'gatsby'

export const ProductImg = styled(Img)`
  padding: 0;
  max-width: 24em;
  margin: auto;
`

export const BreadCrumbLink = styled(Link)`
  text-decoration: none;
  color: black;
  
  :hover {
    color: grey;
    cursor: pointer;
  }
`

export const ContainerProduct = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 7em 0 12em;
  margin: 3em 0 5em 0;

  @media (max-width: ${breakpoints.l}px) {
    padding: 0 1em;
  }

  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    justify-content: center;
    padding: 0 1em;
  }
`

export const LeftProductBox = styled.div`
  min-width: 20em;
  width: 35em;
  margin-right: 2em;

  @media (max-width: ${breakpoints.m}px) {
    min-width: 15em;
    width: 100%;
  }
`

export const RightProductBox = styled.div`
  width: 55em;

  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
`

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 1.5em;
    flex-direction: column;

    h1 {
      align-self: flex-start;
    }
  }
`

export const PriceBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 85%;

  @media (max-width: ${breakpoints.m}px) {
    justify-content: flex-end;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 1em;
    justify-content: center;
  }
`

export const SubTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Price = styled.p`
  font-size: 2em;
  margin: 0.2em 0 0 0;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 1.5em;
  }
`

export const PriceBarred = styled.p`
  font-size: 1.6em;
  text-decoration: line-through;
  color: red;
  margin: 0.2em 0 0 1em;

  @media (min-width: ${breakpoints.s}px) {
    display: none;
  }
`

export const PriceBarred2 = styled.p`
  display: none;
  @media (min-width: ${breakpoints.s}px) {
    display: block;
    font-size: 1.2em;
    text-decoration: line-through;
    color: red;
    margin: 0 0 0 0;
  }
`

export const Separator = styled.div`
  margin: 1em 0 1em 0;
  border-top: 2px solid black;
  width: 8em;
`

export const ProductTitle = styled.h1`
  font-size: 3em;
  word-wrap: break-word;
  font-weight: 400;
  margin: 0;
  line-height: 1.4;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 2em;
  }
`

export const ProductSubTitle = styled.h2`
  font-size: 1.2em;
  margin: 0;
  font-weight: 400;
`

export const ProductDescription = styled.div`
  margin-top: 40px;
  font-family: 'Avenir Light', sans-serif;
  font-size: 1.2em;

  p {
    margin: 0.3em 0;
  }

  strong {
    font-family: 'Avenir Black', sans-serif;
  }

  @media (max-width: ${breakpoints.s}px) {
    img {
      display: block;
      margin: auto;
    }
  }
`
