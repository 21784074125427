import React, { useContext, useEffect, useState } from 'react'
import StoreContext from '../../context/StoreContext'
import selectArrow from '../../data/icons/select-arrow.png'
import {
  AddToCart,
  AddToCartBox,
  NotAvaibleButton,
  Option,
  QuantityInputBox,
  QuantitySelector,
  QuantityText,
  Selector,
  SelectorLabel
} from './styles'
import MoutureRadio from './MoutureRadio'

const ProductForm = ({ product, handleShowModal, getVariantPrice }) => {
  const [quantity, setQuantity] = useState(1)
  const { variants, options, shopifyId } = product

  const {
    addVariantToCart,
    store: { client, adding }
  } = useContext(StoreContext)

  const [price, setPrice] = useState(null)
  const [shopifyProductVariantId, setShopifyProductVariantId] = useState(null)
  const [avaible, setAvaible] = useState(true)
  const [option1, setOption1] = useState('')
  const [option2, setOption2] = useState('')
  const [option3, setOption3] = useState('')

  useEffect(() => {
    if (options.length === 1) {
      setOption1(options[0].values[0])
    } else if (options.length === 2) {
      setOption1(options[0].values[0])
      setOption2(options[1].values[0])
    } else if (options.length === 3) {
      setOption1(options[0].values[0])
      setOption2(options[1].values[0])
      setOption3(options[2].values[0])
    }
  }, [])

  const setOptionValue = (e, index) => {
    const { value } = e.target
    if (index === 0) {
      return setOption1(value)
    } else if (index === 1) {
      return setOption2(value)
    } else if (index === 2) {
      return setOption3(value)
    }
  }

  const setOptionMoutureValue = (value, index) => {
    if (index === 0) {
      return setOption1(value)
    } else if (index === 1) {
      return setOption2(value)
    } else if (index === 2) {
      return setOption3(value)
    }
  }

  useEffect(() => {
    setOptionPrice()
  }, [option1, option2, option3, price])

  const checkAvaiblelity = () => {
    client.product.fetch(shopifyId).then(res => {
      const result = res.variants.filter(
        variant => variant.id === shopifyProductVariantId
      )
      if (result.length !== 0) {
        setAvaible(result[0].available)
      }
    })
  }

  useEffect(() => {
    if (shopifyProductVariantId) {
      checkAvaiblelity()
      getVariantPrice(shopifyProductVariantId)
    } else {
      checkAvaiblelity()
    }
  }, [shopifyProductVariantId])

  const setOptionPrice = () => {
    if (options.length === 1) {
      const variantMatch = variants.filter(variant => variant.title === option1)
      if (variantMatch.length !== 0) {
        setPrice(variantMatch[0].price)
        setShopifyProductVariantId(variantMatch[0].shopifyId)
        // setAvaible(variantMatch[0].availableForSale);
      } else {
        setPrice(null)
        setShopifyProductVariantId(null)
      }
    } else if (options.length === 2) {
      const variantMatch = variants.filter(
        variant => variant.title === option1 + ' / ' + option2
      )
      if (variantMatch.length !== 0) {
        setPrice(variantMatch[0].price)
        setShopifyProductVariantId(variantMatch[0].shopifyId)
        // setAvaible(variantMatch[0].availableForSale);
      } else {
        setPrice(null)
        setShopifyProductVariantId(null)
      }
    } else if (options.length === 3) {
      const variantMatch = variants.filter(
        variant => variant.title === option1 + ' / ' + option2 + ' / ' + option3
      )
      if (variantMatch.length !== 0) {
        setPrice(variantMatch[0].price)
        setShopifyProductVariantId(variantMatch[0].shopifyId)
        // setAvaible(variantMatch[0].availableForSale);
      } else {
        setPrice(null)
        setShopifyProductVariantId(null)
      }
    }
  }

  const quantityNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const handleQuantity = e => {
    const { value } = e.target
    setQuantity(value)
  }

  const handleAddToCart = () => {
    addVariantToCart(shopifyProductVariantId, quantity)
    handleShowModal(true)
  }

  // console.log('price:', price)
  return (
    <div>
      {/* {avaible
        ? (
          <p>{price} €</p>
          )
        : (
          <p>produit non disponible avec les options choisies</p>
          )} */}

      {/* {!price && <p>cette combinaison de variante n'existe pas</p>} */}

      <div>
        {variants.length !== 1
          ? options.map((select, index) => {
              // console.log(select.name)
              if (select.name === 'Mouture') {
                return <MoutureRadio select={select} index={index} setOptionMoutureValue={setOptionMoutureValue} />
              }
              return (
                <div key={`productSelector${index}`}>
                  <SelectorLabel>{select.name}</SelectorLabel>
                  <Selector
                    name={select.name}
                    onChange={e => setOptionValue(e, index)}
                    key={index}
                    arrow={selectArrow}
                  >
                    {select.values.map((option, index) => (
                      <Option key={index} value={option}>
                        {`${option}`}
                      </Option>
                    ))}
                  </Selector>
                </div>
              )
            })
          : null}
      </div>

      {!avaible || !price
        ? (
          <AddToCartBox>
            <NotAvaibleButton>Produit non disponible</NotAvaibleButton>
          </AddToCartBox>
          )
        : (
          <AddToCartBox>
            <QuantityInputBox>
              {/* <p onClick={() => setQuantity(quantity - 1)}>-</p> */}
              <QuantityText>Quantité</QuantityText>
              <QuantitySelector arrow={selectArrow} onChange={handleQuantity}>
                {quantityNumber.map(number => {
                  return (
                    <option
                      key={`quantity${number}`}
                      selected={number === quantity}
                    >
                      0{number}
                    </option>
                  )
                })}
              </QuantitySelector>
              {/* <p onClick={() => setQuantity(quantity + 1)}>+</p> */}
            </QuantityInputBox>

            <AddToCart
              disabled={!avaible || false || adding === true}
              onClick={handleAddToCart}
            >
              Ajouter au panier
            </AddToCart>
          </AddToCartBox>
          )}
    </div>
  )
}

export default ProductForm
