import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import ModalCart from './ModalCart'
import SEO from '../../components/seo'
import ProductForm from '../../components/ProductForm'
import NewProductHeader from './../../components/Shop/TopShop/NewTopShop'
import ProductSuggest from '../../components/Shop/ProductSuggest'
import {
  ProductTitle,
  ProductDescription,
  RightProductBox,
  LeftProductBox,
  ContainerProduct,
  ProductImg,
  ProductSubTitle,
  TitleBar,
  Price,
  Separator,
  PriceBarred,
  PriceBarred2,
  SubTitleBar,
  PriceBox,
  BreadCrumbLink
} from './styles'
import { getPrice } from '../../utils/function'

const ProductPage = ({ pageContext, data }) => {
  // console.log(pageContext)
  const product = data.shopifyProduct

  const [showModal, setShowModal] = useState(false)
  const [variantSelected, setVariantSelected] = useState(product.variants[0])
  const [amount, setAmount] = useState(
    product.variants[0].presentmentPrices.edges[0].node.price.amount
  )
  const [amountBarred, setAmountBarred] = useState(
    product.variants[0].presentmentPrices.edges[0].node.compareAtPrice &&
      product.variants[0].presentmentPrices.edges[0].node.compareAtPrice.amount
  )
  const modalRef = useRef(null)

  const handleShowModal = bool => {
    setShowModal(bool)
  }

  const clickListener = e => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowModal(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', clickListener, true)
    // document.addEventListener('keyup', escapeListener)
    return () => {
      document.removeEventListener('click', clickListener, true)
      // document.removeEventListener('keyup', escapeListener)
    }
  }, [])

  const getVariantPrice = id => {
    const variant = product.variants.find(e => e.shopifyId === id)
    // console.log('variant', variant)
    setVariantSelected(variant)
    if (variant) {
      setAmount(variant.presentmentPrices.edges[0].node.price.amount)
    }

    if (variant && variant.presentmentPrices.edges[0].node.compareAtPrice) {
      setAmountBarred(
        variant.presentmentPrices.edges[0].node.compareAtPrice.amount
      )
    } else {
      setAmountBarred(null)
    }
  }

  const titles = product.title.split(' - ')
  return (
    <>
      <SEO title={product.title} description={product.description} />
      <NewProductHeader title='E-shop' />
      <ContainerProduct id='product'>
        <LeftProductBox>
          {product.images.map(image => (
            <ProductImg
              fluid={image.localFile.childImageSharp.fluid}
              key={image.id}
              alt={product.title}
            />
          ))}
        </LeftProductBox>
        <RightProductBox>
          <p style={{ fontSize: '0.8em', color: 'black' }}>
            <BreadCrumbLink to='/e-shop'>E-shop</BreadCrumbLink>
            /
            <BreadCrumbLink to='/e-shop' state={{ collection: pageContext.collection }}>{pageContext.collection}</BreadCrumbLink>
            /
            {product.title}
          </p>
          <TitleBar>
            <PriceBox>
              <Price>{getPrice(amount)}</Price>
              {amountBarred && (
                <PriceBarred>{getPrice(amountBarred)}</PriceBarred>
              )}
            </PriceBox>
            <ProductTitle>{titles[0]}</ProductTitle>
          </TitleBar>
          <SubTitleBar>
            <ProductSubTitle>{titles[1]}</ProductSubTitle>
            {amountBarred && (
              <PriceBarred2>{getPrice(amountBarred)}</PriceBarred2>
            )}
          </SubTitleBar>
          <Separator />
          <ProductForm
            product={product}
            getVariantPrice={getVariantPrice}
            handleShowModal={handleShowModal}
          />
          <ProductDescription
            dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
          />
        </RightProductBox>
      </ContainerProduct>
      <ProductSuggest collection={pageContext.collection} curentProduct={product.handle} />
      {showModal && (
        <ModalCart
          image={product.images[0]}
          productInfo={{ title: titles[0], subTitle: titles[1] }}
          modalRef={modalRef}
          handleShowModal={handleShowModal}
          variantSelected={variantSelected}
        />
      )}
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        presentmentPrices {
          edges {
            node {
              compareAtPrice {
                amount
              }
              price {
                amount
              }
            }
          }
        }
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default ProductPage
