import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { getPrice } from '../../utils/function'
import { breakpoints } from '../../utils/styles'

const ModalCart = ({ modalRef, productInfo, variantSelected, image }) => {
  // console.log(variantSelected)
  return (
    <ModalWrapper>
      <AddToCartModal ref={modalRef}>
        <LeftBox>
          <ProductImg fluid={image.localFile.childImageSharp.fluid} />
          <ProductInfo>
            <ProductTitle>{productInfo.title}</ProductTitle>
            <ProductSubTitle>{productInfo.subTitle}</ProductSubTitle>
            {variantSelected.selectedOptions[0].value !== 'Default Title' && (
              <Separator />
            )}
            {variantSelected.selectedOptions[0].value !== 'Default Title'
              ? variantSelected.selectedOptions.map(option => (
                  <VariantName>
                    {option.name} <VariantValue>{option.value}</VariantValue>
                  </VariantName>
                ))
              : null}
          </ProductInfo>
        </LeftBox>
        <RightBox>
          <div>
            <Price>{getPrice(variantSelected.price)}</Price>
            <Taxes>Taxes incluses</Taxes>
          </div>
          <ButtonBox>
            <Button to="/cart" checkout>
              Valider la commande
            </Button>
            <Button to="/e-shop">Poursuivre les achats</Button>
          </ButtonBox>
        </RightBox>
      </AddToCartModal>
    </ModalWrapper>
  )
}

export default ModalCart

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.507);
  z-index: 2050;
  animation: lightboxin 0.3s;
  overflow: auto;
  transition: opacity 0.3s;

  @keyframes lightboxin {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`

export const AddToCartModal = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2em 3em;
  max-width: 45em;
  width: 80%;
  height: 12em;
  background-color: white;
  color: black;
  z-index: 1000;
  top: 5em;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 10px;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    width: 85%;
    height: auto;
    margin: 0 auto;
    padding: 2em 1em;
  }
`

export const LeftBox = styled.div`
  display: flex;
  flex-direction: row;
`

export const ProductImg = styled(Img)`
  width: 7em;

  @media (max-width: ${breakpoints.s}px) {
    width: 6em;
  }
`

export const ProductInfo = styled.div`
  margin-left: 1.5em;

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0.5em;
  }
`

export const ProductTitle = styled.p`
  font-size: 2.2em;
  margin: 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.8em;
  }
`

export const ProductSubTitle = styled.p`
  font-size: 0.9em;
  margin: 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 0.7em;
  }
`

export const Separator = styled.div`
  border-top: 2px solid black;
  width: 8em;
  margin: 1em 0 1.3em 0;
`

export const VariantName = styled.p`
  font-family: 'Avenir Light', serif;
  margin: 0.5em 0;
`

export const VariantValue = styled.strong`
  font-weight: bold;
  margin: 0;
`

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 13em;
  height: 100%;

  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
  }
`

export const Price = styled.p`
  font-size: 2.3em;
  text-align: start;
  margin: 0.3em 0 0 0;

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
  }
`

export const Taxes = styled.p`
  font-family: 'Avenir Light', serif;
  text-align: start;
  margin: 0 0;

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
    margin-bottom: 1em;
  }
`

export const ButtonBox = styled.div``

export const Button = styled(Link)`
  display: block;
  background-color: ${props => (props.checkout ? '#8cc63f' : '#2b5c6b')};
  color: ${props => (props.checkout ? '#2b5c6b' : '#eed7b8')};
  margin-bottom: ${props => (props.checkout ? '0.6em' : '0')};
  text-decoration: none;
  width: 100%;
  height: 2.1em;
  text-align: center;
  line-height: 2.1;
  border: 2px solid transparent;
  border-radius: 20px;
  font-family: 'Krona One', serif;
  font-size: 0.8em;

  :hover {
    border: 2px solid ${props => (props.checkout ? '#8cc63f' : '#2b5c6b')};
    background-color: transparent;
    color: ${props => (props.checkout ? '#8cc63f' : '#2b5c6b')};
    cursor: pointer;
  }
`
