import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import {
  CenterPng, Container,
  Title, Wrapper
} from './styles'

const TopShop = ({ title }) => {
  const images = useStaticQuery(graphql`
      query {
          logo: file(relativePath: { eq: "images/big-logo.png" }) {
              childImageSharp {
                  fixed(width: 320) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }

          backgroundLeft: file(
              relativePath: { eq: "images/eshop/eshop_header_left.png" }
          ) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }

          backgroundRight: file(
              relativePath: { eq: "images/eshop/eshop_header_right.png" }
          ) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }

          backgroundCenter: file(
              relativePath: { eq: "images/eshop/eshop_header_center.png" }
          ) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }


  `)
  return (
    <Wrapper>
      <CenterPng src={images.backgroundCenter.childImageSharp.fluid.src} alt='' />
      <Container>
        <Title>{title}</Title>
      </Container>
    </Wrapper>
  )
}

export default TopShop
