import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const SelectorLabel = styled.p`
  font-family: 'Avenir Light', sans-serif;
  margin-bottom: 0.1em;
  margin-left: 0.6em;
`

export const Selector = styled.select`
  border-radius: 20px;
  height: 2.8em;
  padding: 0 1em;
  background-color: white;
  width: 18em;
  border: 2px solid #2b5c6b;
  font-family: 'Krona One',serif;
  color: #2b5c6b;
  font-weight: 400;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('${props => props.arrow}');
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 20px;

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`

export const Option = styled.option`
  margin-bottom: 5em;
`

export const AddToCartBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  height: 5em;
  margin-top: 0.5em;
  width: 100%;

  @media (max-width: ${breakpoints.s}px) {
    justify-content: space-between;
  }
`

export const QuantityInputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3.5em;

  @media (max-width: ${breakpoints.s}px) {
    width: unset;
  }
`
export const QuantityText = styled.p`
  font-size: 0.5em;
  margin-left: 2.5em;
  font-weight: 300;
  margin-bottom: 0.2em;
`

export const QuantitySelector = styled.select`
  width: 4em;
  height: 1.8em;
  border-radius: 20px;
  border: 2px solid #2b5c6b;
  text-align: left;
  background-color: white;
  padding-left: 0.8em;
  font-weight: bold;
  margin-left: 1.5em;
  font-size: 1.3em;
  margin-bottom: 0.9em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('${props => props.arrow}');
  background-repeat: no-repeat;
  background-position: 85% center;
  background-size: 20px;
  outline: none;
  cursor: pointer;

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0;
  }
`

export const AddToCart = styled.button`
  height: 2.2em;
  width: 13.7em;
  border-radius: 20px;
  border: 2px solid transparent;
  background-color: #2b5c6b;
  color: #eed7b8;
  font-size: 1.1em;
  margin-right: 0.5em;
  font-family: 'Krona One', serif;

  @media (hover: hover) {
    :hover {
      border: 2px solid #2b5c6b;
      background-color: white;
      color: #2b5c6b;
      cursor: pointer;
      font-weight: bold;
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 70%;
    margin-right: 0;
  }

  @media (max-width: 321px) {
    font-size: 1em;
  }
`

export const NotAvaibleButton = styled.p`
  background-color: #f15a24;
  text-align: center;
  line-height: 2.8;
  color: white;
  height: 2.8em;
  width: 16.6em;
  margin: 2em 0;
  border-radius: 20px;
  font-size: 0.9em;
  font-family: 'Krona One', sans-serif;

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
