import React, { useEffect, useState, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import StoreContext from '../../../context/StoreContext'

import {
  ProductSuggestContainer,
  ProductSuggestTitle,
  ProductSuggestWrapper
} from './styles'
import {
  Product,
  ProductImgLink,
  ProductImg,
  Title,
  SubTitle,
  RegularPriceTag
} from '../../ProductGrid/styles'
import { getPrice } from '../../../utils/function'

const ProductSuggest = ({ curentProduct, collection }) => {
  const [isAvaible, setIsAvaible] = useState([])
  const [productsToShow, setProductsToShow] = useState([])

  const {
    store: { checkout, client }
  } = useContext(StoreContext)

  const { allShopifyCollection } = useStaticQuery(
    graphql`
        query  {
            allShopifyCollection {
                edges {
                    node {
                        title
                        products {
                            id
                            title
                            handle
                            productType
                            shopifyId
                            availableForSale
                            createdAt
                            images {
                                id
                                originalSrc
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 910) {
                                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                        }
                                    }
                                }
                            }
                            priceRange {
                                maxVariantPrice {
                                    amount
                                }
                                minVariantPrice {
                                    amount
                                }
                            }
                            variants {
                                price
                                presentmentPrices {
                                    edges {
                                        node {
                                            compareAtPrice {
                                                amount
                                            }
                                            price {
                                                amount
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )

/*  const checkAvaiblelity = async shopifyId => {
    return await client.product.fetch(shopifyId).then(res => {
      return res.availableForSale
    })
  }*/

  // console.log(allShopifyProduct)

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      minimumFractionDigits: 2,
      style: 'currency'
    }).format(parseFloat(price || 0))

  useEffect(() => {
    const productsAvaible = []

    const AllProductsFormCollection = allShopifyCollection.edges.find(({ node: { title } }) => title === collection)

    console.log(allShopifyCollection, collection)

    AllProductsFormCollection.node.products.forEach(product => {
      // const response = checkAvaiblelity(product.shopifyId)
        productsAvaible.push({
          ...product
        })
    })

    // setIsAvaible(productsAvaible)

    console.log(productsAvaible)

    const productWithoutSelected = productsAvaible.filter(product => product.handle !== curentProduct)

    const shuffle = array => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    }

    const products = []

    if (productWithoutSelected.length >= 3) {
      const shuffleProducts = shuffle(productWithoutSelected)

      for (let i = 0; i < 3; i++) {
        products.push(shuffleProducts[i])
      }
    }

    setProductsToShow(products)
  }, [])

  // console.log(productsToShow)
  // console.log(isAvaible)

  return (
    <ProductSuggestContainer>
      {productsToShow.length !== 0 && <ProductSuggestTitle>Nos autres cafés</ProductSuggestTitle>}
      <ProductSuggestWrapper>
        {productsToShow.length === 3
          ? productsToShow.map(
              ({
                  id,
                  handle,
                  title,
                  priceRange,
                  images: [firstImage],
                  variants: [firstVariant]
              }) => {
                const titles = title.split(' - ')
                return (
                  <Product key={id}>
                    <ProductImgLink to={`/e-shop/${handle}#product`}>
                      {' '}
                      {firstImage && firstImage.localFile && (
                        <ProductImg
                          fluid={firstImage.localFile.childImageSharp.fluid}
                          alt={handle}
                        />
                      )}
                    </ProductImgLink>
                    <Title>{titles[0]}</Title>
                    <SubTitle>{titles[1]}</SubTitle>
                    <RegularPriceTag>
                      {
                        getPrice(priceRange.minVariantPrice.amount) === getPrice(priceRange.maxVariantPrice.amount)
                          ? getPrice(priceRange.minVariantPrice.amount)
                          : `${getPrice(priceRange.minVariantPrice.amount)} - ${getPrice(priceRange.maxVariantPrice.amount)}`
                      }
                    </RegularPriceTag>
                  </Product>
                )
              }
            )
          : null}
      </ProductSuggestWrapper>
    </ProductSuggestContainer>
  )
}

export default ProductSuggest
