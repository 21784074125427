import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { breakpoints } from '../../utils/styles'

export const Container = styled.div`
  margin: 5em auto 0 auto;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 2em;
    padding: 0 2em;
  }

  @media (max-width: ${breakpoints.s}px) {
    justify-content: center;
    margin: 1em 0;
    height: auto;
    padding: 0;
  }
`

export const ProductsTitle = styled.p`
  display: block;
  font-size: 2em;
  text-align: center;
  margin-bottom: 2em;
  padding: 0 1em;

  @media (max-width: ${breakpoints.xl}px) {
    margin-top: 4em;
  }
`

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isHomePage ? 'center' : 'flex-start'};
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 5em;

  @media (max-width: ${breakpoints.l}px) {
    padding: 0 2em;
  }

  @media (max-width: ${breakpoints.s}px) {
    justify-content: center;
    height: auto;
    padding: 0;
  }
`

export const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  height: 32em;
  width: 25%;
  margin-bottom: 3em;
  transition: transform 0.5s;

  :hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }

  @media (max-width: ${breakpoints.xl}px) {
    margin: 2em 0;
    width: 33%;
  }

  @media (max-width: ${breakpoints.m}px) {
    width: 50%;
    height: auto;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`

export const ProductImgLink = styled(Link)`
  margin: auto;
  height: auto; //TODO test on iphone

  @media (max-width: ${breakpoints.s}px) {
    height: auto;
  }
`

export const ProductImg = styled(Img)`
  width: 13em;

  @media (max-width: ${breakpoints.s}px) {
    width: 19em;
  }
`

export const Title = styled.span`
  font-weight: 300;
  font-size: 1.7em;
  text-align: center;
`

export const SubTitle = styled.span`
  font-weight: 300;
  font-size: 0.7em;
  text-align: center;
  margin-top: 0.3em;
  width: 85%;
`

export const PriceTag = styled.span`
  font-size: 1.3em;
  text-align: center;
  margin-top: 1em;
`

export const RegularPriceTag = styled.span`
  margin-top: 1.5em;
  font-size: 1em;
  text-align: center;
`

export const PriceBarred = styled.p`
  color: red;
  text-decoration: line-through;
  font-size: 0.9em;
`

export const AvaibleButton = styled.p`
  background-color: #8cc63f;
  text-align: center;
  line-height: 2;
  color: white;
  height: 2em;
  margin: 2em 2em;
  border-radius: 20px;
  font-size: 0.9em;
  font-family: 'Avenir Black', sans-serif;
  width: 80%;
  max-width: 18em;
`

export const NotAvaibleButton = styled.p`
  background-color: #f15a24;
  text-align: center;
  line-height: 2;
  color: white;
  height: 2em;
  margin: 2em 1em;
  border-radius: 20px;
  font-size: 0.9em;
  font-family: 'Avenir Black', sans-serif;
  width: 80%;
  max-width: 18em;
`
