import styled from '@emotion/styled'

import { breakpoints } from './../../../utils/styles'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 5em;
  height: 13em;
  background-image: linear-gradient(
    to right,
    #0b1d21 0%,
    #0b1d21 0.10353400000000003%,
    #2a5b6b 100%
  );

  @media (max-width: ${breakpoints.xl}px) {
    height: 10em;
  }

  @media (max-width: ${breakpoints.m}px) {
    height: 7em;
    padding: 0 1.5em;
    text-align: justify;
  }
`

export const CenterPng = styled.img`
  position: absolute;
  top: 0;
  right: 10em;
  margin: 0 auto;
  max-width: 560px;
  width: 35%;
  z-index: 1;

  @media (max-width: ${breakpoints.m}px) {
    right: 4em;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 50%;
    right: 1em;
  }
`

export const Container = styled.div`
  display: flex;
  height: 13em;
  margin-left: 30%;
  align-items: center;
  z-index: 20;

  @media (max-width: ${breakpoints.xl}px) {
    height: 10em;
    margin-left: 25%;
  }
  
  @media (max-width: ${breakpoints.l}px) {
    margin-top: 0;
    margin-left: 25%;
    height: 7em;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0;
  }
`

export const Title = styled.h2`
  color: #eed7b8;
  font-size: 3.2em;
  margin: 0;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 2.3em;
  }

  @media (max-width: 321px) {
    font-size: 2em;
  }
`

export const Separator = styled.div`
  border-top: 1px solid white;
  width: 7em;

  @media (max-width: ${breakpoints.m}px) {
    margin-bottom: 1em;
  }
`

export const Text = styled.p`
  font-size: 1.2em;
  font-family: 'Avenir Light', sans-serif;
  color: white;
  text-align: center;
  font-weight: 300;
  margin: 0;
`

export const Button = styled.a`
  border: 2px solid #eed7b8;
  margin-top: 1em;
  border-radius: 30px;
  height: 1.6em;
  width: fit-content;
  line-height: 1.6;
  color: #2b5c6b;
  background-color:  #eed7b8;
  font-size: 1.3em;
  padding: 0 1em;
  text-align: center;
  text-decoration: none;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      background-color: transparent;
      color: #eed7b8;
      font-weight: bold;
    }
  }
`

export const BottomLogo = styled.div`
  position: absolute;
  bottom: -7em;
  z-index: 30;

  @media (max-width: ${breakpoints.m}px) {
    bottom: -9em;
  }
`
