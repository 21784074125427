import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

import filtreIcon from '../../data/images/product-page/filtre-icon.png'
import expressoIcon from '../../data/images/product-page/expresso-icon.png'
import caftiereIcon from '../../data/images/product-page/caftiere-icon.png'
import pistonIcon from '../../data/images/product-page/piston-icon.png'
import grainIcon from '../../data/images/product-page/grain-icon.png'
import checkedIcon from '../../data/images/product-page/checked-icon.png'
import uncheckedIcon from '../../data/images/product-page/unchecked-icon.png'

import {
  SelectorLabel
} from './styles'

const MoutureRadio = ({ select, index, setOptionMoutureValue }) => {
  const [moutureSelected, setMoutureSelected] = useState('En grain')

  useEffect(() => {
    // console.log(moutureSelected)
    setOptionMoutureValue(moutureSelected, index)
  }, [moutureSelected])

  // console.log(select)
  return (
    <div>
      <SelectorTopLabel>{select.name}</SelectorTopLabel>

      <MoutureContainer>

        <MoutureItemBlock onClick={() => setMoutureSelected('En grain')}>
          <MoutureIconBox>
            <MoutureIcon style={{ width: '3.7em', height: '3.8em' }} src={grainIcon} />
          </MoutureIconBox>
          <MoutureTitle>En grain</MoutureTitle>
          <InputRadio
            src={moutureSelected === 'En grain' ? checkedIcon : uncheckedIcon}
          />
        </MoutureItemBlock>

        <MoutureItemBlock onClick={() => setMoutureSelected('Filtre')}>
          <MoutureIconBox>
            <MoutureIcon src={filtreIcon} />
          </MoutureIconBox>
          <MoutureTitle>Filtre</MoutureTitle>
          <InputRadio src={moutureSelected === 'Filtre' ? checkedIcon : uncheckedIcon} />
        </MoutureItemBlock>

        <MoutureItemBlock onClick={() => setMoutureSelected('Expresso')}>
          <MoutureIconBox>
            <MoutureIcon style={{ width: '4.5em', height: '4.3em' }} src={expressoIcon} />
          </MoutureIconBox>
          <MoutureTitle>Expresso</MoutureTitle>
          <InputRadio
            src={moutureSelected === 'Expresso' ? checkedIcon : uncheckedIcon}
          />
        </MoutureItemBlock>

        <MoutureItemBlock onClick={() => setMoutureSelected('Cafetière italienne')}>
          <MoutureIconBox>
            <MoutureIcon src={caftiereIcon} />
          </MoutureIconBox>
          <MoutureTitle>Caftière <br /> Italienne</MoutureTitle>
          <InputRadio
            src={moutureSelected === 'Cafetière italienne' ? checkedIcon : uncheckedIcon}
          />
        </MoutureItemBlock>

        <MoutureItemBlock onClick={() => setMoutureSelected('Piston')}>
          <MoutureIconBox>
            <MoutureIcon src={pistonIcon} />
          </MoutureIconBox>
          <MoutureTitle>Piston</MoutureTitle>
          <InputRadio
            src={moutureSelected === 'Piston' ? checkedIcon : uncheckedIcon}
          />
        </MoutureItemBlock>

      </MoutureContainer>
    </div>
  )
}

export default MoutureRadio

export const SelectorTopLabel = styled(SelectorLabel)`
  margin-bottom: 1em;
`

export const MoutureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 30em;

  @media (max-width: ${breakpoints.s}px) {
    flex-wrap: wrap;
    padding: 0 2em;
  }
`

export const MoutureItemBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 12em;
  cursor: pointer;

  @media (max-width: ${breakpoints.s}px) {
    width: 40%;
  }
`

export const MoutureTitle = styled.p`
  color: #2B5C6B;
  font-size: .8em;
  margin-top: .3em;
  text-align: center;
  display: block;
  height: 2em;
`
export const MoutureIconBox = styled.div`
  display: flex;
  height: 4.5em;
`

export const MoutureIcon = styled.img`
  width: 3.2em;
  height: auto;
`

export const InputRadio = styled.img`
  width: 2em;
`
